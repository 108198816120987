import { createApi } from 'api/common/createApi'
import config, { API_TYPES } from 'app-config'

export const devHubApi = createApi({
  baseURL: config[API_TYPES.WPP_DEVHUB_API],
})

export const masterDataApi = createApi({
  baseURL: config[API_TYPES.WPP_MASTER_DATA_API],
})

export const facadeApi = createApi({
  baseURL: '/api',
})

export const userDetailsProxyApi = createApi({
  baseURL: '/_apps/user-details/api',
})

export const apiInstances = [masterDataApi, devHubApi, facadeApi, userDetailsProxyApi]
