export enum FEATURE_TYPES {
  WPP_NO_CODE_APPS = 'WPP_NO_CODE_APPS',
  WPP_INVITE_TO_COLLABORATE = 'WPP_INVITE_TO_COLLABORATE',
  WPP_REQUEST_ASSISTANCE_EMAIL = 'WPP_REQUEST_ASSISTANCE_EMAIL',
}

type FEATURE_TYPES_NAMES = keyof typeof FEATURE_TYPES extends `WPP_${infer P}` ? P : never
export const featureFlags: Record<FEATURE_TYPES, `WPP_TEMPLATE_${FEATURE_TYPES_NAMES}`> = {
  WPP_NO_CODE_APPS: 'WPP_TEMPLATE_NO_CODE_APPS',
  WPP_INVITE_TO_COLLABORATE: 'WPP_TEMPLATE_INVITE_TO_COLLABORATE',
  WPP_REQUEST_ASSISTANCE_EMAIL: 'WPP_TEMPLATE_REQUEST_ASSISTANCE_EMAIL',
} as const

const featureFlagsConfig = Object.keys(FEATURE_TYPES).reduce((acc, type) => {
  const flags =
    process.env.NODE_ENV !== 'development' ? featureFlags[type as FEATURE_TYPES] : process.env[`REACT_APP_${type}`]
  return { ...acc, [type]: flags }
}, {} as Record<FEATURE_TYPES, string>)

export default featureFlagsConfig
