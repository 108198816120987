import { css } from '@emotion/react'

export const GlobalStyles = css`
  // TODO add class selector here when CL will support it
  &::part(tooltip-content) {
    white-space: normal;
    word-wrap: break-word;
    overflow-wrap: break-word;
    word-break: break-word;
    hyphens: auto;
  }
  .table-context-menu {
    width: 186px;
  }
`
